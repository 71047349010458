.rowWrapper > td {
    padding: 0 !important;
}

.rowWrapper td .ant-table,
.rowWrapper td .ant-table-small {
    margin-block: 0 !important;
    margin-inline: 0 !important;
}

.alert-status {
    margin: -8px;
}

.alert-priority,
.user-is_active {
    width: 102px;
    text-align: center;
}

.alertSpinner {
    height: 100%;
}

.alertSpinner .ant-spin-container {
    height: 100%;
}

.alert__content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.alert__list {
    flex-grow: 1;
}

.alert-status__tag {
    padding: 9px 0;
    width: 100%;
    text-align: center;
    border-radius: 0;
    border-color: transparent;
}

.alert-status__tag_standard {
    background-color: white;
}

.alert-status__tag_closed {
    background-color: white;
    color: #959595;
}

.tab-wrapper {
    overflow-y: scroll;
}

.row-margin-reset {
    padding: 0 12px;
}

.alert-info {
    display: flex;
    border-bottom: 1px solid #f1f1f1;
    padding: 2px 0;
}

.alert-info:last-child {
    border-bottom: none;
}

.alert-info__required {
    color: red;
}

.alert-info-body {
    white-space: pre-wrap;
}

.error-marker {
    position: absolute;
    top: 9px;
    right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.alert-card .anticon-close,
.ant-drawer-title {
    color: #fff !important;
}

.alert-card .shortInput {
    width: 280px;
}

.alert-card .dateInput {
    width: 136px;
}

.alert-card .ant-select-selection-item {
    max-width: 400px;
}

.alerts-table_header {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
}

.report-checkbox {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.field__rule-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alert-card__spinner,
.alert-card__spinner > .ant-spin-container,
.alert-card__spinner > .ant-spin-container > .ant-form {
    height: 100%;
}

.card-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    row-gap: 16px;
}

.card-content__tab {
    flex: 1 0;
    overflow: hidden;
}

.card-content__tab .ant-tabs {
    height: 100%;
}

/** Стилізація табів та контенту в них початок*/
.card-content__tab .ant-tabs-content-holder {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-left: 1px solid #445271;
    border-right: 1px solid #445271;
    border-bottom: 1px solid #445271;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.card__table-pagination {
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
}

.card-content__tab .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #445271;
}

.card-content__tab .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
}

.card-content__tab .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #445271;
    color: white;
}

.card-content__tab .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}

.card-content__tab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 1px solid #445271;
}

.editable-form-item {
    margin-bottom: 0;
}

.card-content__tab .scrollable-wrapper {
    overflow-x: scroll;
}

/** Стилізація табів та контенту в них кінець*/

.rule-scrolable-table {
    overflow-x: scroll;
    width: calc(100vw - 68px);
}

.alert-data-of-legal .ant-form-item {
    margin-bottom: 4px !important;
}

.alert-data-of-legal .ant-form-item-control-input {
    margin-bottom: 4px !important;
}
